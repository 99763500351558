import { FC, memo } from 'react';

type Props = {
  className?: string;
};

const Divider: FC<Props> = ({ className = '' }) => {
  return <hr className={`divider ${className}`} />;
};

export default memo(Divider);
