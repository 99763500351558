import { useSession } from 'next-auth/react';
import jwtDecode from 'jwt-decode';
import { useEffect, useState } from 'react';
import { JWTData } from '@/app/types/models';

/**
 * Custom hook for accessing user's data encoded in JWT
 */
export default function useJWTData() {
  const { data } = useSession();
  const [jwtData, setJwtData] = useState<Pick<
    JWTData,
    'username' | 'retailerId' | 'emailId' | 'fname' | 'lname' | 'id'
  > | null>(null);
  const accessToken = data?.user?.accessToken;
  const refreshToken = data?.user?.refreshToken;

  /*
  Decode access token data
   */
  useEffect(() => {
    if (accessToken) {
      const decodedData = jwtDecode<JWTData>(accessToken);
      if (decodedData) {
        setJwtData(decodedData);
      }
    }
  }, [accessToken]);

  return {
    jwtData,
    refreshToken,
    accessToken,
  };
}
