'use client';

import { AnalyticsBrowser } from '@segment/analytics-next';
import { useEffect } from 'react';
import { usePathname, useSearchParams } from 'next/navigation';
import { ANALYTICS_KEY } from '@/app/utils/constants';

export const analytics = AnalyticsBrowser.load({
  writeKey: ANALYTICS_KEY,
});

export const PageAnalytics = () => {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  useEffect(() => {
    analytics?.page();
  }, [pathname, searchParams]);

  return null;
};
