import { useSession } from 'next-auth/react';
import { useMemo } from 'react';

export const useIsAuthenticated = () => {
  const { status } = useSession();
  const isAuthenticated = useMemo(() => status === 'authenticated', [status]);
  return {
    isAuthenticated,
  };
};
