import { AppContext } from '@/app/appProvider';
import { getFeatureFlags } from '@/app/services/featureService';
import { QueryKey } from '@/app/utils/constants';
import { convertSecondsToMilliseconds } from '@/app/utils/helpers';
import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';

export const useGetFeatures = (enabled: boolean) => {
  const { setFeatures } = useContext(AppContext);

  return useQuery({
    queryKey: [QueryKey.FEATURES],
    queryFn: async () => {
      const { data } = await getFeatureFlags();
      setFeatures({ ablyChat: data.featureFlags.IsAblyChatEnabled });
      return data;
    },
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    staleTime: convertSecondsToMilliseconds(1200),
    enabled,
  });
};
