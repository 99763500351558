import { FC, memo } from 'react';
import CustomLink from '@/app/components/navigation/CustomLink';
import { TextDecorationEnum } from '@/app/utils/constants';

export type SidebarSubItemProps = {
  name: string;
  href?: string;
  onClick?: () => void;
  isActive?: boolean;
};

const SidebarSubItem: FC<SidebarSubItemProps> = ({ name, href = '#', onClick, isActive }) => {
  return (
    <div className={'sidebar-sub-item'}>
      <CustomLink
        textDecoration={TextDecorationEnum.none}
        linkPrimary={false}
        href={href}
        onClick={onClick}
        isActive={isActive}
      >
        {name}
      </CustomLink>
    </div>
  );
};

export default memo(SidebarSubItem);
