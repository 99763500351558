import React, { memo, ReactNode, useState } from 'react';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from 'reactstrap';
import cn from 'classnames';
import SidebarSubItem, { SidebarSubItemProps } from './SidebarSubItem';
import { usePathname, useRouter } from 'next/navigation';

export type SidebarItemProps = {
  id: string;
  name: string;
  isCollapsible: boolean;
  open?: boolean;
  icon: ReactNode;
  href?: string;
  subItems?: SidebarSubItemProps[];
  onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
} & CollapsibleProps;

type CollapsibleProps =
  | { isCollapsible: true; subItems: SidebarSubItemProps[] }
  | { isCollapsible: false };

const SidebarItem = ({
  onClick,
  isCollapsible,
  open,
  name,
  subItems,
  id,
  href,
  icon,
}: SidebarItemProps) => {
  const [openIds, setOpenIds] = useState<string[]>(open ? [id] : []);
  const pathname = usePathname();
  const router = useRouter();

  const isActive = pathname === href;

  const toggleDropdown = (targetId: string) => {
    // If the item is not collapsible, do nothing
    if (!isCollapsible) return;

    const isItemOpen = openIds.includes(targetId);
    if (isItemOpen) {
      // If the item is already open, remove its targetId from the opensIds array
      setOpenIds((prevIds) => prevIds.filter((id) => id !== targetId));
    } else {
      // If the item is closed, add its targetId to the opensIds array
      setOpenIds((prevIds) => [...prevIds, targetId]);
    }
  };

  const onClickHeaderWithHref = () => href && router.push(href);

  return (
    <div>
      <Accordion
        {...{
          toggle: (accordionItem: string) => toggleDropdown(accordionItem),
          open: openIds,
        }}
        className={cn(`sidebar-item`, {
          ['sidebar-item-not-collapsible']: !isCollapsible,
        })}
      >
        <AccordionItem onClick={onClick}>
          <AccordionHeader
            targetId={id}
            className={cn({
              ['active']: isActive,
            })}
            onClick={onClickHeaderWithHref}
          >
            {icon}
            <p className={'accordion-title'}>{name}</p>
          </AccordionHeader>
          <AccordionBody accordionId={id}>
            {subItems?.map((subItem) => (
              <SidebarSubItem key={subItem.name} {...subItem} />
            ))}
          </AccordionBody>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default memo(SidebarItem);
