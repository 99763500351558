import Script from 'next/script';
import AblyChat from '@/app/utils/ably-chat';

export default function AblyChatSnippet() {
  return (
    <>
      <Script
        id="ablychat-snippet"
        src="https://cdn.ably.io/lib/ably.min-1.js"
        strategy="lazyOnload"
        onLoad={() => {
          AblyChat.init();
        }}
      />
    </>
  );
}
