// @ts-nocheck
/** Temporary workaround, since for now there are no types for this package */
function initFive9Proactive(chatTitle) {
  var options = {
    tenant: 'Installation Made Easy',
    title: `${chatTitle}`,
    showProfiles: false,
    restAPI: 'https://app.five9.com',
    chatConsole: 'https://app.five9.com/consoles/ChatConsole/index.html',
    notificationType: 'notification',
    customChatFields: {
      name: {
        value: '',
        show: true,
        label: 'Name',
      },
      email: {
        value: '',
        show: true,
        label: 'Email',
      },
    },
    chatOptions: {
      allowRequestLiveAgent: false,
      showEmailButton: false,
      hideDuringAfterHours: false,
      useBusinessHours: true,
      enableCallback: false,
      callbackList: '',
      showPrintButton: false,
      playSoundOnMessage: true,
      allowCustomerToControlSoundPlay: false,
      allowUsabilityMenu: false,
    },
  };

  Five9ProactiveChat.init(options);
  var parameters = {
    pageId: 'Page-1',
    profiles: 'Chat',
  };
  Five9ProactiveChat.startNewPage(parameters);
}

function initFive9Widget(retailerName, chatTitle) {
  const profileName = retailerName.replace("'", ""); //Remove apostrophe from retailer names. eg. Lowe's -> Lowes
  var options = {
    rootUrl: 'https://app.five9.com/consoles/',
    type: 'chat',
    title: `${chatTitle}`,
    tenant: 'Installation Made Easy',
    profiles: `${profileName} Chat`,
    showProfiles: false,
    autostart: true,
    theme: 'default-theme.css',
    surveyOptions: {
      showComment: true,
      requireComment: false,
    },
    fields: {
      name: {
        value: '',
        show: true,
        label: 'Name',
      },
      email: {
        value: '',
        show: true,
        label: 'Email',
      },
    },
    playSoundOnMessage: true,
    allowCustomerToControlSoundPlay: false,
    showEmailButton: false,
    hideDuringAfterHours: false,
    useBusinessHours: true,
    showPrintButton: false,
    allowUsabilityMenu: false,
    enableCallback: false,
    callbackList: '',
    allowRequestLiveAgent: false,
    showOnlyWhenInSession: true,
  };
  var target = document.body;

  // This causes the chat window to pop up and down
  var observer = new MutationObserver(function (mutations) {
    mutations.forEach(function (mutation) {
      if (mutation.addedNodes.length && mutation.addedNodes[0].className === 'five9-frame') {
        if (!Five9SocialWidget.isMobile) {
          document.getElementById('five9-maximize-button')?.click();
        }
        setTimeout(function () {
          document.getElementById('five9-minimize-icon')?.click();
          // Increasing the time so that it doesn't look like an apparition
        }, 800);
      }
    });
  });

  var config = { attributes: false, childList: true, characterData: false };
  observer.observe(target, config);

  Five9SocialWidget.addWidget(options);
}

const removeFive9Widget = () => {
  if (Five9SocialWidget) {
    Five9SocialWidget.removeWidget();
  }
};

export { initFive9Widget, initFive9Proactive, removeFive9Widget };
