import { memo, ReactNode } from 'react';
import { Offcanvas, OffcanvasBody, OffcanvasHeader } from 'reactstrap';
import styles from './OffCanvas.module.scss';
import Loader from '@/app/components/shared/Loader/Loader';
import { OffcanvasProps } from 'reactstrap/types/lib/Offcanvas';

type Props = {
  /**
   * Title of the offcanvas header
   */
  title: string;
  /**
   * Boolean which defines if the offcanvas is open or not
   */
  isOpen: boolean;
  /**
   * Function to toggle the offcanvas
   */
  toggle: () => void;
  /**
   * Body of the offcanvas
   */
  children: ReactNode;
  /**
   * Custom class name for the offcanvas
   */
  className?: string;
  /**
   * Boolean which defines if the loader is active or not
   */
  isLoading?: boolean;
} & OffcanvasProps;

const OffCanvas = ({
  isOpen,
  toggle,
  children,
  title,
  className = '',
  isLoading,
  ...rest
}: Props) => {
  return (
    <Offcanvas isOpen={isOpen} toggle={toggle} className={className} {...rest}>
      <OffcanvasHeader toggle={toggle} className={styles.title}>
        {title}
      </OffcanvasHeader>
      <OffcanvasBody>
        {isLoading ? <Loader isLoading={isLoading} minHeight={100} /> : children}
      </OffcanvasBody>
    </Offcanvas>
  );
};

export default memo(OffCanvas);
