import Axios from 'axios';
import { getSession } from 'next-auth/react';

export const api = Axios.create({
  baseURL: `${process.env.NEXT_PUBLIC_API_URL}`,
});

api.interceptors.request.use(async (request) => {
  const session = await getSession();
  if (session && request.headers) {
    request.headers.Authorization = `Bearer ${session?.user?.accessToken}`;
  }
  return request;
});
