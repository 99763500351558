import Script from 'next/script';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useIsAuthenticated } from '@/app/hooks/useIsAuthenticated';
import { initFive9Proactive, initFive9Widget, removeFive9Widget } from '@/app/utils/five9-chat';
import { useContext } from 'react';
import { getRetailerConfig } from '@/app/services/retailerService';
import { AppContext } from '@/app/appProvider';
import { useTranslations } from 'next-intl';

export default function Five9ChatSnippets() {
  const { isAuthenticated } = useIsAuthenticated();
  const [widgetReady, setWidgetReady] = useState(false);

  const appContext = useContext(AppContext);
  const retailerConfig = getRetailerConfig(appContext.retailerId);
  const t = useTranslations('components.chatWidget');

  useLayoutEffect(() => {
    /** This handles the case, when user logs out and the script is already loaded.  */
    if (!widgetReady && isAuthenticated) {
      const widgetScript = document.getElementById('five9Widget');
      if (widgetScript) setWidgetReady(true);
    }
  }, [widgetReady, isAuthenticated]);

  useEffect(() => {
    if (widgetReady && isAuthenticated) {
      initFive9Widget(retailerConfig?.name, t("title"));
    }
    return () => {
      if (widgetReady) {
        removeFive9Widget();
      }
    };
  }, [isAuthenticated, widgetReady]);

  return (
    <>
      <Script
        id="embedScript"
        strategy="lazyOnload"
        src="https://embed.rcrsv.io/DTODRWt2X3wgFMypUcAyEkssX4EICYXFzzMFRIlIxeabKou86NxwKzblznebOYCK"
      />
      <Script
        id="five9Widget"
        strategy="lazyOnload"
        onLoad={() => {
          setWidgetReady(true);
        }}
        src="https://app.five9.com/consoles/SocialWidget/five9-social-widget.min.js"
      />
      <Script
        id="five9Proactive"
        strategy="lazyOnload"
        onLoad={() => {
          initFive9Proactive(t("title"));
        }}
        src="https://app.five9.com/consoles/ProactiveChat/javascripts/five9proactivechat.js"
      />
    </>
  );
}
