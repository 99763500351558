import React, { useContext } from 'react';
import { getRetailerConfig } from '@/app/services/retailerService';
import { AppContext } from '@/app/appProvider';
import { useTranslations } from 'next-intl';

export default function Logo() {
  const appContext = useContext(AppContext);
  const retailerConfig = getRetailerConfig(appContext.retailerId);
  const t = useTranslations('pages.logo');

  return (
    <div className="text-center pt-1">
      {retailerConfig?.logo}
      <div className="text-white fs-7 mt-1 fw-bold">{t('poweredByInstallationMadeEasy')}</div>
    </div>
  );
}
