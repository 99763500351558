'use client';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { useLocale, useTranslations } from 'next-intl';
import { availableLanguages, LangEnum } from '@/app/utils/constants';
import { usePathname, useRouter } from '@/app/navigation';
import { BsGlobe } from 'react-icons/bs';
import { MessageKeys } from 'use-intl';

export default function LanguageSwitcher() {
  const locale = useLocale();
  const router = useRouter();
  const pathname = usePathname();
  const t = useTranslations('components.languages');

  const handleChange = (locale: LangEnum) => {
    router.replace(pathname, { locale });
    router.refresh();
  };

  /**
   * Hide language switcher if there's only one default language supported
   */
  if (availableLanguages.length < 2) return null;

  const shortCodeTranslation = `shortcodes.${locale}` as MessageKeys<any, any>;

  return (
    <div className="position-absolute top-0 end-0">
      <UncontrolledDropdown>
        <DropdownToggle color="link" caret className="text-light opacity-75 text-decoration-none">
          <span className="text-light">
            <BsGlobe className="d-none d-md-inline me-2" />
            {t(shortCodeTranslation).toUpperCase()} ({locale === LangEnum.EN_US ? 'US' : 'CAN'})
          </span>
        </DropdownToggle>
        <DropdownMenu>
          {availableLanguages.map((l) => (
            <DropdownItem key={l} onClick={() => handleChange(l)}>
              {t(l)}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  );
}
